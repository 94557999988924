<template>
    <div class="py-4 container-fluid">
      <div class="row mb-4">
        <div class="col-lg-12 position-relative z-index-2">
            
          <div class="row mt-4">
            <div class="col-lg-4 col-md-6 mt-4">
              <chart-holder-card
                title="Website Views"
                subtitle="Last Campaign Performance"
                update="campaign sent 2 days ago"
              >
                <reports-bar-chart
                  :chart="{
                    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                    datasets: {
                      label: 'Sales',
                      data: [50, 20, 10, 22, 50, 10, 40],
                    },
                  }"
                />
              </chart-holder-card>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
              <chart-holder-card
                title="Daily Sales"
                subtitle="(<span class='font-weight-bolder'>+15%</span>) increase in today sales."
                update="updated 4 min ago"
                color="success"
              >
                <reports-line-chart
                  :chart="{
                    labels: [
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ],
                    datasets: {
                      label: 'Mobile apps',
                      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                    },
                  }"
                />
              </chart-holder-card>
            </div>
            <div class="col-lg-4 mt-4">
              <chart-holder-card
                title="Completed Tasks"
                subtitle="Last Campaign Performance"
                update="just updated"
                color="dark"
              >
                <reports-line-chart
                  id="tasks-chart"
                  :chart="{
                    labels: [
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ],
                    datasets: {
                      label: 'Mobile apps',
                      data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                    },
                  }"
                />
              </chart-holder-card>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  <script>
  import ChartHolderCard from "./components/ChartHolderCard.vue";
  import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
  import ReportsLineChart from "@/examples/Charts/ReportsLineChart.vue";
//   import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
//   import ProjectCard from "./components/ProjectCard.vue";
//   import TimelineList from "@/examples/Cards/TimelineList.vue";
//   import TimelineItem from "@/examples/Cards/TimelineItem.vue";
  import logoXD from "@/assets/img/small-logos/logo-xd.svg";
  import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
  import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
  import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
  import logoJira from "@/assets/img/small-logos/logo-jira.svg";
  import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
  import team1 from "@/assets/img/team-1.jpg";
  import team2 from "@/assets/img/team-2.jpg";
  import team3 from "@/assets/img/team-3.jpg";
  import team4 from "@/assets/img/team-4.jpg";
  export default {
    name: "dashboard-default",
    data() {
      return {
        logoXD,
        team1,
        team2,
        team3,
        team4,
        logoAtlassian,
        logoSlack,
        logoSpotify,
        logoJira,
        logoInvision,
      };
    },
    components: {
      ChartHolderCard,
      ReportsBarChart,
      ReportsLineChart,
    //   MiniStatisticsCard,
    //   ProjectCard,
    //   TimelineList,
    //   TimelineItem,
    },
  };
  </script>
  