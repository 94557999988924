<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
